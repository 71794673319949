import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 3";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "module-4-questions-and-answers"
    }}>{`Module 4 questions and answers`}</h1>
    <p>{`These were some questions people asked during the live recording. You might have thought similar questions while going through the module.`}</p>
    <h2 {...{
      "id": "why-charge-if-data-is-already-sent-to-browser"
    }}>{`Why charge if data is already sent to browser?`}</h2>
    <Vimeo id="399452250" mdxType="Vimeo" />
    <h2 {...{
      "id": "when-does-gatsby-browser-vs-gatsby-ssr-run"
    }}>{`When does gatsby-browser vs. gatsby-ssr run?`}</h2>
    <Vimeo id="397711768" mdxType="Vimeo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      